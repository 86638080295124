import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            My name is <span className="purple">Suneru Perera, </span>
            and I'm a grade 12 student from Cameron Heights Collegiate Institute in <span className="purple"> Waterloo, Ontario. </span> 
            My favourite subject in school is math, with a <span className="purple"> 99.7% </span> average across the 3 maths (Calc, Functions, Data).
            <br />I plan on attending university in a software engineering / computer science program 
            to pursue career in software development and furthering my knowledge.  
            <br>
            </br>I enjoy 
            <span className="purple"> full stack development, </span> and building programs to solve real world problems.  For example at my first internship
            I built a crowdfunding web-app that accumulated <span className="purple"> $5000 </span> in funds for the elderly community!  For the sinhalese-canadian community, I developed a 
            website that is used to keep track of events, and keep everyone closer together.
            <br>
            </br>
             For my personal projects, I enjoy working with <span className="purple"> AI </span>, training datasets, and developing applications that can be used on a global scale.
             For example, I've built applications like;<span className="purple"> AI Suicide Detector, </span> seizure detector, and my startup; <span className="purple"> Husi, </span>
             which I plan on launching soon.
             Feel free to go to my projects tab and check out the projects I've built!
             <br>
             </br>
            <br />
            Outside of school/work, some things I enjoy are:
            <br />
            <br />
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Weightlifting/Bodybuilding (Chasing new PR's every week!)
            </li>
            <li className="about-activity">
              <ImPointRight /> Gaming (Fortnite, Minecraft, Clash Royale)
            </li>
            <li className="about-activity">
              <ImPointRight /> Playing sports (Swimming, Cricket, Soccer)
            </li>
          </ul>

          <p style={{ textAlign: "justify" }}>
            <br />
            I recognize that a balance between work and personal life is key, which is why I am always in the gym striving to achieve
            new PR's and develop a healthy lifestyle.  I enjoy freestyle swimming, playing soccer recretionally and playing Cricket with
            my community!  I also enjoy gaming, my favourites being Fortnite, Minecraft and Clash of Clans!
            <br />
            <br />

          </p>


          <p style={{ color: "rgb(155 126 172)" }}>
            "We have two lives, and the second begins when we realize we only have one."{" "}
          </p>
          <footer className="blockquote-footer">Confucius</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
